// react components
import React, {
    useEffect,
    useState,
} from 'react'
import axios from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    LinkHelperSite,
} from 'components'

// data
import {
    api_url_portfolio_contact_form,
    api_url_portfolio_newsletter_form,
    api_url_portfolio_submit_form,
    defaultReduxState,
    reduxFormModalFormShowSite,
    reduxFormSetIsSuccessToastOpenSite,
    reduxFormSetRefreshSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    EditBlockSite,
    TemplateBlock783BanquePostaleSite,
    TemplateBlock783StripeWrapperSite,
} from 'pages'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template783SiteSerializer,
    formInfoTypeSite,
    formValueTypeSite,
} from 'serializers/site'

// services
import {
    axiosErrorHandlerSite,
    getApiUrlSite,
    getApiUrlSiteV2,
    getAxiosHeadersSite,
    getStylesNew,
    getTextSite,
    onClickIsModalSite,
    validateEmailSite,
} from 'services'

// props
type TemplateBlock783SiteProps = {
    blockId: string
    collapseIsOpen?: boolean
    content: Template783SiteSerializer | Template783SiteSerializer[] | undefined
    contentArray?: Template783SiteSerializer[]
    formInfo: formInfoTypeSite
    formValue: formValueTypeSite
    inFormModal?: boolean
    isEditHovered: boolean
    isInComponent?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    setFormValue: React.Dispatch<formValueTypeSite> | undefined
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock783Site: React.FC<TemplateBlock783SiteProps> = React.memo(({
    blockId,
    collapseIsOpen,
    content,
    contentArray,
    formInfo,
    formValue,
    inFormModal,
    isEditHovered,
    isInComponent,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    setFormValue,
    styles,
    stylesEdit,
}) => {

    if (Array.isArray(content)) {
        return (
            <React.Fragment>
                {content.map((item, i) => (
                    <TemplateBlock783HelperSite
                        key={item.id}
                        blockId={`${blockId}-${item.id}`}
                        collapseIsOpen={collapseIsOpen}
                        content={item}
                        contentArray={contentArray || content}
                        formInfo={formInfo}
                        formValue={formValue}
                        inFormModal={inFormModal}
                        isEditHovered={isEditHovered}
                        isInComponent={isInComponent}
                        mainParentId={mainParentId}
                        object={object}
                        parentArray={parentArray}
                        parentStyles={parentStyles}
                        setFormValue={setFormValue}
                        styles={styles}
                        stylesEdit={stylesEdit}
                    />
                ))}
            </React.Fragment>
        )
    }
    if (formInfo?.form_is_external) {
        return (
            <TemplateBlock783BanquePostaleSite
                blockId={blockId}
                content={content}
                contentArray={contentArray ? (contentArray) : (content ? [content] : undefined)}
                formInfo={formInfo}
                formValue={formValue}
                // inFormModal={inFormModal}
                isEditHovered={isEditHovered}
                isInComponent={isInComponent}
                mainParentId={mainParentId}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
                setFormValue={setFormValue}
                styles={styles}
                stylesEdit={stylesEdit}
            />
        )
    }
    if (formInfo?.form_is_subscription) {
        return (
            <TemplateBlock783StripeWrapperSite
                blockId={blockId}
                content={content}
                contentArray={contentArray ? (contentArray) : (content ? [content] : undefined)}
                formInfo={formInfo}
                formValue={formValue}
                // inFormModal={inFormModal}
                isEditHovered={isEditHovered}
                isInComponent={isInComponent}
                mainParentId={mainParentId}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
                setFormValue={setFormValue}
                styles={styles}
                stylesEdit={stylesEdit}
            />
        )
    }
    return (
        <TemplateBlock783HelperSite
            blockId={blockId}
            collapseIsOpen={collapseIsOpen}
            content={content}
            contentArray={contentArray ? (contentArray) : (content ? [content] : undefined)}
            formInfo={formInfo}
            formValue={formValue}
            inFormModal={inFormModal}
            isEditHovered={isEditHovered}
            isInComponent={isInComponent}
            mainParentId={mainParentId}
            object={object}
            parentArray={parentArray}
            parentStyles={parentStyles}
            setFormValue={setFormValue}
            styles={styles}
            stylesEdit={stylesEdit}
        />
    )
})

// helpers

// props
type TemplateBlock783HelperSiteProps = {
    blockId: string
    collapseIsOpen?: boolean
    content: Template783SiteSerializer | undefined
    contentArray: Template783SiteSerializer[] | undefined
    formInfo: formInfoTypeSite
    formValue: formValueTypeSite
    inFormModal?: boolean
    isEditHovered: boolean
    isInComponent?: boolean
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    parentArray?: number[]
    parentStyles?: any
    setFormValue: React.Dispatch<formValueTypeSite> | undefined
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
    submitPayment?: () => void
}

// main
export const TemplateBlock783HelperSite: React.FC<TemplateBlock783HelperSiteProps> = React.memo(({
    blockId,
    collapseIsOpen,
    content,
    contentArray,
    formInfo,
    formValue,
    inFormModal,
    isEditHovered,
    isInComponent,
    mainParentId,
    object,
    parentArray,
    parentStyles,
    setFormValue,
    styles,
    stylesEdit,
    submitPayment,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSiteportfolioid = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.id)
    const reduxFormSitemodalForm = useSelector((state: defaultReduxState) => state.reduxFormSite.modalForm)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    const [stylesNew, setStylesNew] = useState<{
        buttonStyle: CustomCSSProperties | undefined,
        hoverStyleBackground: string | undefined,
        hoverStyleBorderColor: string | undefined,
        hoverStyleColor: string | undefined,
        hasHoverStyle: boolean,
    } | undefined>(applyStyles())
    const [newStyles, setNewStyles] = useState<any>(stylesNew?.buttonStyle)

    useEffect(() => {
        const tempStyles = applyStyles()
        setStylesNew(tempStyles)
        setNewStyles(applyStyles()?.buttonStyle)
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    function applyStyles() {
        try {
            const buttonStyle = getStylesNew(reduxModalSite, styles, stylesEdit)
            const hoverStyleBackground = buttonStyle?.hoverBackground
            const hoverStyleBorderColor = buttonStyle?.hoverBorderColor
            const hoverStyleColor = buttonStyle?.hoverColor
            const hasHoverStyle = Boolean(hoverStyleBackground || hoverStyleBorderColor || hoverStyleColor)
            return {
                buttonStyle: buttonStyle,
                hoverStyleBackground: hoverStyleBackground,
                hoverStyleBorderColor: hoverStyleBorderColor,
                hoverStyleColor: hoverStyleColor,
                hasHoverStyle: hasHoverStyle,
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock783HelperSite',
                'applyStyles',
            ))
        }
    }

    function onHover(direction: string) {
        try {
            if (stylesNew?.hasHoverStyle) {
                if (direction === 'over') {
                    if (stylesNew.buttonStyle) {
                        const newnewStyle = Object.assign({}, stylesNew.buttonStyle)
                        if (stylesNew.hoverStyleBackground) newnewStyle.background = stylesNew.hoverStyleBackground
                        if (stylesNew.hoverStyleBorderColor) newnewStyle.borderColor = stylesNew.hoverStyleBorderColor
                        if (stylesNew.hoverStyleColor) newnewStyle.color = stylesNew.hoverStyleColor
                        setNewStyles(newnewStyle)
                    } else {
                        setNewStyles(undefined)
                    }
                } else {
                    if (stylesNew.buttonStyle) {
                        setNewStyles(stylesNew.buttonStyle)
                    } else {
                        setNewStyles(undefined)
                    }
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock783HelperSite',
                'onHover',
            ))
        }
    }

    function submitContactForm(submitFormType?: string) {
        try {
            const formData: any = new FormData()
            const formDataContentType: any = new FormData()
            let postUrl = getApiUrlSite(submitFormType ? api_url_portfolio_submit_form : api_url_portfolio_contact_form, reduxModalSite)
            if (submitFormType === 'content_type') {
                const contentType = reduxFormSitemodalForm?.contentType
                const app = contentType?.split('_')[0]
                const model = contentType?.split('_')[1]
                postUrl = getApiUrlSiteV2(`${app}/form/${model}/update/${reduxFormSitemodalForm?.objectId}/`, reduxModalSite)
            }
            if (object.is_mixed_media) {
                formData.append('mixed_block_id', JSON.stringify({ key: 'mixed_block_id', label: 'mixed_block_id', value: `${formInfo?.id}` }))
                formDataContentType.append('mixed_block_id', formInfo?.id)
                formData.append('portfolio_id', JSON.stringify({ key: 'portfolio_id', label: 'portfolio_id', value: `${reduxCacheSiteportfolioid}` }))
                formDataContentType.append('portfolio_id', reduxCacheSiteportfolioid)
            } else {
                formData.append('block_id', JSON.stringify({ key: 'block_id', label: 'block_id', value: `${formInfo?.id}` }))
                formDataContentType.append('block_id', formInfo?.id)
            }
            formData.append('from', JSON.stringify({ key: 'from', label: 'From', value: window.location.href }))
            formDataContentType.append('from', window.location.href)
            if (submitFormType && formInfo?.detailId) {
                formData.append('detail_id', JSON.stringify({ key: 'detail_id', label: 'detail_id', value: formInfo.detailId.split('-')[0] }))
                formDataContentType.append('detail_id', formInfo.detailId.split('-')[0])
            }
            const errorsData: {
                name: string
                errorText: string
            }[] = []
            formInfo?.fields?.map((val) => {
                if (val.required && !formValue?.fields[val.name!]?.value) {
                    errorsData.push({
                        errorText: reduxText[7489],
                        name: val.name!,
                    })
                }
                if (val.field_type === 'email' && formValue?.fields[val.name!] && !validateEmailSite(formValue.fields[val.name!].value!)) {
                    errorsData.push({
                        errorText: reduxText[7490],
                        name: val.name!,
                    })
                }
                if (val.data_json?.required_if) {
                    const requiredIf = val.data_json.required_if
                    const requiredIfField = requiredIf?.split('=')[0]
                    const requiredIfValue = requiredIf?.split('=')[1]
                    // @ts-ignore
                    const requiredIfFieldObj = formValue?.fields[requiredIfField]
                    if (requiredIfValue === 'true' && requiredIfFieldObj?.value) {
                        if (!formValue?.fields[val.name!]?.value) {
                            errorsData.push({
                                errorText: reduxText[7489],
                                name: val.name!,
                            })
                        }
                    }
                    if (requiredIfFieldObj) {
                        const requiredIfValueSplit = requiredIfValue.split('__')
                        if (requiredIfValueSplit.length === 1) {
                            if (requiredIfFieldObj?.value === requiredIfValue) {
                                if (!formValue?.fields[val.name!]?.value) {
                                    errorsData.push({
                                        errorText: reduxText[7489],
                                        name: val.name!,
                                    })
                                }
                            }
                        } else {
                            if (requiredIfValueSplit[0] === 'not') {
                                if (requiredIfFieldObj?.value !== requiredIfValueSplit[1]) {
                                    if (!formValue?.fields[val.name!]?.value) {
                                        errorsData.push({
                                            errorText: reduxText[7489],
                                            name: val.name!,
                                        })
                                    }
                                }
                            } else {
                                if (requiredIfFieldObj?.value === requiredIfValueSplit[1]) {
                                    if (!formValue?.fields[val.name!]?.value) {
                                        errorsData.push({
                                            errorText: reduxText[7489],
                                            name: val.name!,
                                        })
                                    }
                                }
                            }
                        }
                    }
                }
                if (val.data_json?.different_from) {
                    const diffrentFrom = val.data_json.different_from
                    const diffrentFromField = diffrentFrom.field
                    // @ts-ignore
                    const diffrentFromFieldObj = formValue?.fields[diffrentFromField]
                    if (formValue?.fields[val.name!]?.value === diffrentFromFieldObj?.value) {
                        errorsData.push({
                            errorText: diffrentFrom.error_message,
                            name: val.name!,
                        })
                    }
                }
                if (val.field_type === 'fileinput') {
                    const fileField: any = formValue?.fields[val.name!]?.value
                    if (fileField?.type) {
                        const filetoUpload = fileField
                        let filetoUploadName = filetoUpload.name
                        if (filetoUploadName?.length! > 100) {
                            filetoUploadName = filetoUploadName?.slice(filetoUploadName.length - 100)
                        }
                        if (submitFormType === 'content_type') {
                            formData.append(val.name, filetoUpload, filetoUploadName)
                            formDataContentType.append(val.name, filetoUpload, filetoUploadName)
                        } else {
                            formData.append(`file-${val.name}`, filetoUpload, filetoUploadName)
                            formDataContentType.append(`file-${val.name}`, filetoUpload, filetoUploadName)
                        }
                    }
                } else {
                    formData.append(val.name!, JSON.stringify(formValue?.fields[val.name!]))
                    formDataContentType.append(val.name!, formValue?.fields[val.name!]?.value)
                }
                return false
            })
            if (errorsData.length > 0) {
                const newErrors: {
                    [key: string]: string
                } = {}
                newErrors.main_error = reduxText[7534]
                errorsData.map((val2) => {
                    newErrors[val2.name] = val2.errorText
                    return false
                })
                setFormValue!({
                    ...formValue!,
                    errors: newErrors,
                    helpers: {
                        ...formValue?.helpers,
                        buttonDisabled: true,
                    }
                })
            } else {
                setFormValue!({
                    ...formValue!,
                    helpers: {
                        ...formValue?.helpers,
                        buttonDisabled: true,
                        isLoading: true,
                    }
                })
                axios({
                    data: submitFormType === 'content_type' ? formDataContentType : formData,
                    headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                    method: submitFormType === 'content_type' ? 'patch' : 'post',
                    url: postUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setFormValue!({
                            ...formValue!,
                            errors: {},
                            fields: {},
                            helpers: {
                                ...formValue?.helpers,
                                buttonDisabled: true,
                                isLoading: false,
                                isSuccess: true,
                                isSuccessText: content?.text2,
                            }
                        })
                        if (inFormModal) {
                            dispatch(reduxFormSetRefreshSite('PrivateDetailPageSite'))
                            dispatch(reduxFormModalFormShowSite(false))
                            dispatch(reduxFormSetIsSuccessToastOpenSite(true))
                        }
                        if (object.id === 318409) {  // TODO dynamic
                            dispatch(reduxFormModalFormShowSite(true))
                        }
                    })
                    .catch((error) => {
                        if (process.env.NODE_ENV === 'development') console.log(error)
                        setFormValue!({
                            ...formValue!,
                            errors: error.response?.data,
                            helpers: {
                                ...formValue?.helpers,
                                buttonDisabled: true,
                                isLoading: false,
                            }
                        })
                        axiosErrorHandlerSite({
                            apiUrl: postUrl,
                            component: 'TemplateBlock783HelperSite',
                            dispatch,
                            error,
                            formFields: JSON.stringify(formValue?.fields),
                            reduxAuth,
                            reduxModalSite,
                            reference: 'submitContactForm',
                            skipAlertStatusCode: [400],
                        })
                    })
            }
        } catch (error) {
            setFormValue!({
                ...formValue!,
                helpers: {
                    ...formValue?.helpers,
                    isLoading: false,
                }
            })
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock783HelperSite',
                'submitContactForm',
            ))
        }
    }

    function submitNewsletterForm() {
        try {
            const errorsData: any = []
            // allFields.map((val) => {
            // 	if (val.required && !fields[val.tag]) errorsData.push(val)
            // 	return false
            // })
            if (errorsData.length > 0) {
                const newErrors: any = {}
                newErrors.main_error = reduxText[7534]
                errorsData.map((val2: any) => {
                    newErrors[val2.tag] = reduxText[7489]
                    return false
                })
                setFormValue!({
                    ...formValue!,
                    errors: newErrors,
                    helpers: {
                        ...formValue?.helpers,
                        buttonDisabled: true,
                    }
                })
            } else if (!validateEmailSite(formValue?.fields.EMAIL?.value!)) {
                setFormValue!({
                    ...formValue!,
                    errors: {
                        ...formValue!.errors,
                        EMAIL: reduxText[7490],
                        main_error: reduxText[7534],
                    },
                    helpers: {
                        ...formValue?.helpers,
                        buttonDisabled: true,
                    }
                })
            } else {
                setFormValue!({
                    ...formValue!,
                    helpers: {
                        ...formValue?.helpers,
                        buttonDisabled: true,
                        isLoading: true,
                    }
                })
                const formData = new FormData()
                const postUrl = getApiUrlSite(api_url_portfolio_newsletter_form, reduxModalSite)
                formData.append('block_id', JSON.stringify({ key: 'block_id', label: 'block_id', value: `${formInfo?.id}` }))
                formData.append('from', JSON.stringify({ key: 'from', label: 'From', value: window.location.href }))
                formData.append('merge_fields', JSON.stringify(formValue?.fields))
                formData.append('language_id', JSON.stringify({ key: 'language_id', label: 'language_id', value: `${reduxModalSite.languageId}` }))
                if (process.env.NODE_ENV === 'development') console.log(postUrl)
                axios({
                    data: formData,
                    headers: getAxiosHeadersSite(reduxAuth, reduxModalSite, dispatch),
                    method: 'post',
                    url: postUrl,
                })
                    .then((response) => {
                        if (process.env.NODE_ENV === 'development') console.log(response)
                        setFormValue!({
                            ...formValue!,
                            errors: {},
                            fields: {},
                            helpers: {
                                ...formValue?.helpers,
                                buttonDisabled: true,
                                isLoading: false,
                                isSuccess: true,
                            }
                        })
                    })
                    .catch((error) => {
                        if (process.env.NODE_ENV === 'development') console.log(error)
                        setFormValue!({
                            ...formValue!,
                            errors: error.response?.data,
                            helpers: {
                                ...formValue?.helpers,
                                buttonDisabled: false,
                            }
                        })
                        axiosErrorHandlerSite({
                            apiUrl: postUrl,
                            component: 'TemplateBlock783HelperSite',
                            dispatch,
                            error,
                            formFields: JSON.stringify(formValue?.fields),
                            reduxAuth,
                            reduxModalSite,
                            reference: 'submitNewsletterForm',
                            skipAlertStatusCode: [400],
                        })
                    })
            }
        } catch (error) {
            setFormValue!({
                ...formValue!,
                helpers: {
                    ...formValue?.helpers,
                    isLoading: false,
                }
            })
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock783HelperSite',
                'submitNewsletterForm',
            ))
        }
    }

    function onButtonClick() {
        try {
            if (object.action === 'submit_form') {
                if (submitPayment) {
                    submitPayment()
                } else if (formInfo?.form_is_newsletter) {
                    submitNewsletterForm()
                } else {
                    submitContactForm(formInfo?.formSubmitType)
                }
            } else if (content && contentArray) {
                onClickIsModalSite(dispatch, reduxAuth, reduxModalSite, content, object, contentArray)
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock783HelperSite',
                'onButtonClick',
            ))
        }
    }

    if ((object.data_json?.hidden_if_no_link || object.data_json?.hidden_if_past_event) && !content?.absolute_site_url) return null

    // @ts-ignore
    const disabled = content?.text?.disabled

    return (
        <LinkHelperSite
            id={blockId}
            action={object.action}
            className={`template-block-783${isEditHovered ? ' is-edit-hovered' : ''}${(object.action === 'submit_form' && formValue?.helpers.buttonDisabled) ? ' disabled' : ''} ${deviceType}`}
            dowloadFile={disabled ? undefined : content?.download_file}
            linkUrl={disabled ? undefined : content?.link_url}
            onClick={disabled ? () => undefined : onButtonClick}
            onCustomMouseLeave={() => onHover('leave')}
            onCustomMouseOver={() => onHover('over')}
            style={newStyles}
            // TODO dynamic
            to={disabled ? undefined : (content?.absolute_site_url?.includes('{{ instance.id }}') ? `${content?.absolute_site_url.replace('{{ instance.id }}', window.location.pathname.split('entity-detail/')[1].split('-')[0])}` : content?.absolute_site_url)}
        >
            {(collapseIsOpen && [324516, 330972, 331729, 332275].includes(object.id!))
                ? 'Masquer détails' // TODO dynamic
                : (object.action === 'submit_form' && !content?.text) ? (formInfo?.form_is_newsletter ? reduxText[8709] : reduxText[8515]) : getTextSite(content?.text, reduxModalSite, reduxText)
            }
            <EditBlockSite
                isInComponent={isInComponent}
                mainParentId={mainParentId || object.id!}
                object={object}
                parentArray={parentArray}
                parentStyles={parentStyles}
            />
        </LinkHelperSite>
    )
})
