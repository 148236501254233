// react components
import React from 'react'
import {
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    viewUrlSite,
} from 'data'

// props
type StaffHelperSiteProps = {
    detailId: string | undefined
    pageSlug: string | undefined
}

// main
export const StaffHelperSite: React.FC<StaffHelperSiteProps> = React.memo(({
    detailId,
    pageSlug,
}) => {

    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSiteportfolio = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio)
    const reduxFormSitenavigationMode = useSelector((state: defaultReduxState) => state.reduxFormSite.navigationMode)

    if (!reduxAuth?.settings?.user?.is_staff || reduxFormSitenavigationMode) return null

    return (
        <div className='staff-helper-site'>
            <a
                className='main-icon-block-site'
                href={`${viewUrlSite}/form/website/${reduxCacheSiteportfolio?.id}/page-container/-/${pageSlug || reduxCacheSiteportfolio?.home_page_slug}/${detailId || ''}`}
                rel='noopener noreferrer'
                target='_blank'
            >
                <i className='main-icon mo-new-icon-pencil-alt-solid' />
            </a>
            {reduxAuth.settings.user.id === 1 && (
                <>
                    <a
                        className='main-icon-block-site ml'
                        href={`http://localhost:8100/form/website/${reduxCacheSiteportfolio?.id}/page-container/-/${pageSlug || reduxCacheSiteportfolio?.home_page_slug}/${detailId || ''}`}
                        rel='noopener noreferrer'
                        target='_blank'
                    >
                        <i className='main-icon mo-new-icon-diagram-project-solid' />
                    </a>
                    <a
                        className='main-icon-block-site ml'
                        href={`http://localhost:8101/${reduxCacheSiteportfolio?.id}${window.location.pathname}${window.location.search}`}
                        rel='noopener noreferrer'
                        target='_blank'
                    >
                        <i className='main-icon mo-new-icon-medium-types-16' />
                    </a>
                </>
            )}
        </div>
    )
})
