// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    Dialog,
} from '@mui/material'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// data
import {
    defaultReduxState,
    reduxFormModalFormShowSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
} from 'serializers/site'

// services
import {
    getStylesNew,
} from 'services'

import * as templateBlock from './index'

// props
type TemplateBlock824SiteProps = {
    blockId: string
    content: PortfolioPageContentListSiteSerializer[]
    contentArray?: any[]
    detailId: string | undefined
    devId: string | undefined
    isEditHovered: boolean
    isInDetailPage?: boolean
    object: PortfolioPageContentListSiteSerializer
    pageSlug: string | undefined
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock824Site: React.FC<TemplateBlock824SiteProps> = React.memo(({
    blockId,
    content,
    contentArray,
    detailId,
    devId,
    isEditHovered,
    isInDetailPage,
    object,
    pageSlug,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const reduxFormSiteeditAutoSave = useSelector((state: defaultReduxState) => state.reduxFormSite.editAutoSave)
    const reduxFormSiteeditAutoSaved = useSelector((state: defaultReduxState) => state.reduxFormSite.editAutoSaved)
    const reduxFormSitehoveredBlockId = useSelector((state: defaultReduxState) => state.reduxFormSite.hoveredBlockId)
    const reduxFormSitemodalForm = useSelector((state: defaultReduxState) => state.reduxFormSite.modalForm)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [stylesNew, setStylesNew] = useState<CustomCSSProperties | undefined>(applyStyles())

    useEffect(() => {
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    function applyStyles() {
        try {
            return getStylesNew(reduxModalSite, styles, stylesEdit) || {}
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock824Site',
                'applyStyles',
            ))
        }
    }

    if (!reduxFormSitemodalForm?.isOpen) return null

    return (
        <Dialog
            className='mo-dialog-class-site'
            classes={{ paper: 'mo-dialog-wrapper full-width' }}
            onClose={() => dispatch(reduxFormModalFormShowSite(false))}
            maxWidth='xl'
            open
        >
            <div
                id={blockId}
                className={`template-block-824 detail${isEditHovered ? ' is-edit-hovered' : ''}${reduxModalSite.isWindows} ${deviceType}`}
                style={stylesNew}
            >
                {content?.filter(obj => obj.active === true).map((val) => {
                    // @ts-ignore
                    const Component = templateBlock[`TemplateBlock${val.layout}Site`]
                    const contentBlockId = `content-new-child-${val.id}-layout-${val.layout}`
                    return (
                        <Component
                            key={val.id}
                            blockId={contentBlockId}
                            content={val.content}
                            contentArray={contentArray}
                            detailId={detailId}
                            devId={devId}
                            inFormModal
                            isEditHovered={reduxFormSitehoveredBlockId === val.id}
                            isInDetailPage={isInDetailPage}
                            object={val}
                            pageSlug={pageSlug}
                            styles={val.styles}
                            stylesEdit={reduxFormSiteeditAutoSave[val.id!]?.styles || reduxFormSiteeditAutoSaved[val.id!]?.styles}
                        />
                    )
                })}
            </div>
            <div className='mo-dialog-header-close-wrap-form-modal'>
                <button
                    className='mo-dialog-header-close'
                    onClick={() => dispatch(reduxFormModalFormShowSite(false))}
                >
                    <i className='main-icon mo-new-icon-times-solid' />
                </button>
            </div>
        </Dialog>
    )
})
