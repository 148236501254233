// react components
import React, {
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    IonContent,
} from '@ionic/react'
import {
    CancelTokenSource,
} from 'axios'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    useLocation,
} from 'react-router-dom'

// components
import {
    LinkHelperSite,
    LoaderSite,
    StaffHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxModalErrorEventHandlerSite,
    reduxModalSliderDetailShowHideSite,
    reduxModalSliderOnNextSite,
    reduxModalSliderOnPrevSite,
} from 'data'

// pages
import {
    ContentBlockSite,
    NotFoundPortfolioSite,
} from 'pages'

// serializers
import {
    PageDetailSiteSerializer,
    PortfolioPageContentListSiteSerializer,
    errorStatusTypeSite,
} from 'serializers/site'

// services
import {
    getDataSite,
    getHostOriginSite,
    getModalAbsoluteUrlSite,
    getStylesToRetrieve,
} from 'services'

// props
type DetailModalSiteProps = {
    detailId?: string
    pageSlug: string
}

// main
export const DetailModalSite: React.FC<DetailModalSiteProps> = React.memo(({
    detailId,
    pageSlug,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [axiosCancelToken, setAxiosCancelToken] = useState<CancelTokenSource>()
    const [currencyId, setCurrencyId] = useState<number | undefined>(reduxModalSite.currencyId)
    const [errorStatus, setErrorStatus] = useState<errorStatusTypeSite>({})
    const [isLoading, setIsLoading] = useState(false)
    const [languageId, setLanguageId] = useState<number>(reduxModalSite.languageId)
    const [pageContentItems, setPageContentItems] = useState<PortfolioPageContentListSiteSerializer[] | undefined>(undefined)
    const [pageObject, setPageObject] = useState<PageDetailSiteSerializer>()

    const [fontFamily, setFontFamily] = useState<string>()
    const [linkColor, setLinkColor] = useState<string>()
    const [newPageStyles, setNewPageStyles] = useState<{ [key: string]: string | undefined }>({})

    useEffect(() => {
        getPortfolioData()
    }, [
        detailId,
        pageSlug,
    ])

    useEffect(() => {
        if (languageId !== reduxModalSite.languageId) {
            getPortfolioData()
            setLanguageId(reduxModalSite.languageId)
        } else if (currencyId !== reduxModalSite.currencyId) {
            getPortfolioData()
            setCurrencyId(reduxModalSite.currencyId)
        }
    }, [
        reduxModalSite.currencyId,
        reduxModalSite.languageId,
    ])

    useEffect(() => {
        const tempPageStyles: { [key: string]: string | undefined } = {}
        if (getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'backgroundImage')) {
            tempPageStyles.backgroundImage = getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'backgroundImage')
        } else {
            tempPageStyles.background = getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'background')
        }
        tempPageStyles.borderRadius = getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'modalBorderRadius') || getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'modalBorderRadius')
        tempPageStyles.color = getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'color')
        tempPageStyles.paddingTop = deviceType === 'is-mobile' ? '53px' : undefined
        setFontFamily(getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'fontFamily'))
        setNewPageStyles(tempPageStyles)
        setLinkColor(getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'linkColor') || getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'linkColor'))
    }, [
        pageObject?.styles,
        reduxCacheSite.portfolio?.styles,
        reduxModalSite,
    ])

    const contentRef = useRef<HTMLIonContentElement>(null)
    const scrollToTop = () => {
        try {
            // @ts-ignore
            if (document.getElementById('mo-dms-content-id-site') && document.getElementById('mo-dms-content-id-site').scrollTo) document.getElementById('mo-dms-content-id-site').scrollTo(0, 0)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'DetailModalSite',
                'scrollToTop',
            ))
        }
    }

    function getPortfolioData() {
        try {
            getDataSite({
                axiosCancelToken,
                component: 'DetailModalSite',
                detailId,
                devId: getHostOriginSite() === 'prod' ? undefined : location.pathname.split('/')[1],
                dispatch,
                errorStatus,
                location,
                pageSlug,
                reduxAuth,
                reduxCacheSite,
                reduxModalSite,
                scrollToTop,
                setAxiosCancelToken,
                setErrorStatus,
                setIsLoading,
                setPageContentItems,
                setPageObject,
            })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'DetailModalSite',
                'getPortfolioData',
            ))
        }
    }

    function onNextSlide() { // next slide function
        try {
            scrollToTop()
            dispatch(reduxModalSliderOnNextSite())
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'DetailModalSite',
                'onNextSlide',
            ))
        }
    }

    function onPrevSlide() { // prev slide function
        try {
            scrollToTop()
            dispatch(reduxModalSliderOnPrevSite())
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'DetailModalSite',
                'onPrevSlide',
            ))
        }
    }

    const dataLength = reduxModalSite.data.length
    const multiData = dataLength > 1
    const nextIndex = reduxModalSite.data[Number(reduxModalSite.slideIndex) + 1] ? (Number(reduxModalSite.slideIndex) + 1) : (multiData && 0)
    const prevIndex = reduxModalSite.data[Number(reduxModalSite.slideIndex) - 1] ? (Number(reduxModalSite.slideIndex) - 1) : (multiData && (dataLength - 1))
    // @ts-ignore
    const hasNext = reduxModalSite.objectId && reduxModalSite.data[nextIndex]
    // @ts-ignore
    const hasPrev = reduxModalSite.objectId && reduxModalSite.data[prevIndex]
    // @ts-ignore
    const nextObject = reduxModalSite.data[nextIndex]
    // @ts-ignore
    const prevObject = reduxModalSite.data[prevIndex]
    const backUrl = reduxModalSite.listUrl

    return (
        <div className='detail-modal-site'>
            <IonContent
                id='mo-dms-content-id-site'
                className={`mo-hide-ion-content-scrollbar${reduxModalSite.isWindows}`}
                ref={contentRef}
            >
                <LinkHelperSite
                    action='generic_link'
                    className={`dms-backdrop ${deviceType}`}
                    forceTo={backUrl}
                    onClick={() => dispatch(reduxModalSliderDetailShowHideSite(false))}
                    to={undefined}
                    style={{
                        background: getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'modalBackground') || getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'modalBackground'),
                        opacity: getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'modalOpacity') || getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'modalOpacity'),
                    }}
                />
                <div className={`dms-navigation ${deviceType}`}>
                    {hasPrev && (
                        <LinkHelperSite
                            action='generic_link'
                            className={`dms-previous ${deviceType}`}
                            forceTo={hasPrev ? getModalAbsoluteUrlSite(prevObject?.absolute_site_url) : undefined}
                            onClick={() => onPrevSlide()}
                            to={undefined}
                            style={{
                                color: getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'modalColor') || getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'modalColor'),
                            }}
                        >
                            <i className={`dms-icon mo-new-icon-chevron-left-solid ${deviceType}`} />
                        </LinkHelperSite>
                    )}
                    <LinkHelperSite
                        action='generic_link'
                        className={`dms-close ${deviceType}`}
                        forceTo={backUrl}
                        onClick={() => dispatch(reduxModalSliderDetailShowHideSite(false))}
                        to={undefined}
                        style={{
                            color: getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'modalColor') || getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'modalColor'),
                        }}
                    >
                        <i className={`dms-close-icon mo-new-icon-times-solid ${deviceType}`} />
                    </LinkHelperSite>
                    {hasNext && (
                        <LinkHelperSite
                            action='generic_link'
                            className={`dms-next ${deviceType}`}
                            forceTo={hasNext ? getModalAbsoluteUrlSite(nextObject?.absolute_site_url) : undefined}
                            onClick={() => onNextSlide()}
                            to={undefined}
                            style={{
                                color: getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'modalColor') || getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'modalColor'),
                            }}
                        >
                            <i
                                className={`dms-icon mo-new-icon-chevron-right-solid ${deviceType}`}
                            />
                        </LinkHelperSite>
                    )}
                </div>
                <div className={`dms-modal-wrap ${deviceType}`}>
                    {(errorStatus.portfolio || errorStatus.page || errorStatus.pageCcontent)
                        ? (
                            <NotFoundPortfolioSite
                                inModal
                                statusCode={errorStatus.portfolio || errorStatus.page || errorStatus.pageCcontent}
                            />
                        ) : (
                            <div
                                className={`slug-page-content-wrap lg-${reduxModalSite.languageId} ${deviceType}`}
                                style={{
                                    background: getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'background'),
                                    borderColor: getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'modalBorderColor') || getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'modalBorderColor'),
                                    borderRadius: getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'modalBorderRadius') || getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'modalBorderRadius'),
                                    borderStyle: getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'modalBorderStyle') || getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'modalBorderStyle'),
                                    borderWidth: getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'modalBorderWidth') || getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'modalBorderWidth'),
                                    color: getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSite.portfolio?.styles, '', 'color'),
                                    fontFamily: fontFamily,
                                }}
                            >
                                <div
                                    className={`slug-page-content ${deviceType}`}
                                    style={newPageStyles}
                                >
                                    <div
                                        className={`slug-page-content-list ${deviceType}`}
                                        style={{
                                            padding: getStylesToRetrieve(reduxModalSite, undefined, pageObject?.styles, '', 'padding'),
                                        }}
                                    >
                                        {reduxCacheSite.portfolio && pageObject && pageContentItems?.filter(obj => obj.active === true).map((val) => (
                                            <ContentBlockSite
                                                key={val.id}
                                                detailId={detailId}
                                                devId={getHostOriginSite() === 'prod' ? undefined : location.pathname.split('/')[1]}
                                                isEditHovered={false}
                                                isInDetailPage
                                                linkColor={linkColor}
                                                object={val}
                                                pageSlug={pageSlug}
                                                stylesEdit={undefined}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
                <StaffHelperSite
                    detailId={detailId}
                    pageSlug={pageSlug}
                />
            </IonContent>
            {isLoading && (
                <LoaderSite isOpen />
            )}
        </div>
    )
})
