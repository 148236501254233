// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    LinkHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
    groupingIdsSite,
    reduxModalErrorEventHandlerSite,
    reduxModalSetNavbarIsTransparentSite,
    reduxModalSetNavbarOpenIdSite,
    reduxModalSetNavbarTypeSite,
} from 'data'

// pages
import {
    Animated780Site,
    InfiniteBlock,
} from 'pages'

// serializers
import {
    CustomCSSProperties,
    FormFieldSerializer,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    formInfoTypeSite,
    formValueTypeSite,
} from 'serializers/site'

// services
import {
    getStylesNew,
    getStylesToRetrieve,
    getStylesToRetrieveNew,
    onClickIsModalSite,
} from 'services'

import * as templateBlock from './index'

// props
type TemplateBlock780SiteProps = {
    blockId: string
    collapseIsOpen?: boolean
    content: PortfolioPageContentListSiteSerializer[]
    contentArray?: any[]
    contentIdsAll?: any[]
    detailId: string | undefined
    devId: string | undefined
    formInfo?: formInfoTypeSite
    formInputObj?: FormFieldSerializer
    formValue?: formValueTypeSite
    hubComponentType?: string
    hubContentSlug?: string
    inAbout?: any
    inFormModal?: boolean
    inListIndex?: number
    infiniteContentId?: number
    isEditHovered: boolean
    isInComponent?: boolean
    isInDetailPage?: boolean
    isInLayoutTypePr?: boolean
    isInVideoBlock?: boolean
    linkColor: string | undefined
    listIndex?: number
    mainParentId?: number
    object: PortfolioPageContentListSiteSerializer
    pageNumberNew?: number
    pageSlug: string | undefined
    parentArray?: number[]
    parentDropdownId?: number
    parentNavbarId?: number
    parentStyles?: any
    setFormValue?: React.Dispatch<formValueTypeSite>
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock780Site: React.FC<TemplateBlock780SiteProps> = React.memo(({
    blockId,
    collapseIsOpen,
    content,
    contentArray,
    contentIdsAll,
    detailId,
    devId,
    formInfo,
    formInputObj,
    formValue,
    hubComponentType,
    hubContentSlug,
    inAbout,
    inFormModal,
    inListIndex,
    infiniteContentId,
    isEditHovered,
    isInComponent,
    isInDetailPage,
    isInLayoutTypePr,
    isInVideoBlock,
    linkColor,
    listIndex,
    mainParentId,
    object,
    pageNumberNew,
    pageSlug,
    parentArray,
    parentDropdownId,
    parentNavbarId,
    parentStyles,
    setFormValue,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSiteportfoliostyles = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.styles)
    const reduxFormSitehoveredBlockId = useSelector((state: defaultReduxState) => state.reduxFormSite.hoveredBlockId)
    const reduxFormSiteparentContent = useSelector((state: defaultReduxState) => state.reduxFormSite.parentContent)
    const reduxFormSiteeditAutoSave = useSelector((state: defaultReduxState) => state.reduxFormSite.editAutoSave)
    const reduxFormSiteeditAutoSaved = useSelector((state: defaultReduxState) => state.reduxFormSite.editAutoSaved)
    const reduxFormSitenavigationMode = useSelector((state: defaultReduxState) => state.reduxFormSite.navigationMode)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxPlayerSitekeepNextBlocks = useSelector((state: defaultReduxState) => state.reduxPlayerSite.keepNextBlocks)
    const reduxPlayerSiteplayingInlineId = useSelector((state: defaultReduxState) => state.reduxPlayerSite.playingInlineId)

    const deviceType = reduxModalSite.deviceType

    const [connectedHidden, setConnectedHidden] = useState(false)
    const [stylesNew, setStylesNew] = useState<{
        animation: string | undefined,
        hiddenHorizontalScroll: boolean
        styles: CustomCSSProperties | undefined,
        styles2: CustomCSSProperties | undefined,
        stylesActive: {
            background: string | undefined,
            opacity: string | undefined,
        } | undefined,
    } | undefined>(applyStyles())

    useEffect(() => {
        if (object.data_json?.connected_hidden && !reduxFormSitenavigationMode) {
            getChildren(object, Number(object.data_json.connected_hidden))
        }
    }, [])

    useEffect(() => {
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    useEffect(() => {
        if (object.layout_type === 'n' && !object.is_mixed_media) {
            if (!(deviceType === 'is-web' && object.data_json?.navbar_is_side_menu)) {
                dispatch(reduxModalSetNavbarTypeSite('top'))
            } else {
                dispatch(reduxModalSetNavbarTypeSite('side'))
            }
        }
    }, [
        deviceType,
        object.id,
        reduxModalSite.specialSize,
    ])

    useEffect(() => {
        if (object.layout_type === 'n' && !object.is_mixed_media) {
            if (!(deviceType === 'is-web' && object.data_json?.navbar_is_side_menu)) {
                const bg = getStylesToRetrieveNew(reduxModalSite, content[0]?.styles, undefined, 'background')
                let isTransparent = false
                if (typeof bg === 'string') {
                    isTransparent = bg.split(',')[3] !== '1)'
                }
                dispatch(reduxModalSetNavbarIsTransparentSite(isTransparent))
            }
        }
    }, [
        content[0]?.styles,
        deviceType,
        reduxModalSite.specialSize,
    ])

    function applyStyles() {
        try {
            const tempStyles = getStylesNew(reduxModalSite, styles, stylesEdit) || {}
            tempStyles.realDisplay = tempStyles.display
            const detailObject = !object.is_list && (object.content_detail || object.content_ids?.[inListIndex!] || contentArray?.[inListIndex!])
            if (detailObject?.styles_extra) {
                if ((!tempStyles.background || tempStyles.background === 'inherit') && detailObject.styles_extra.background) {
                    tempStyles.background = detailObject.styles_extra.background
                }
            }
            const tempStyles2 = getStylesNew(reduxModalSite, styles, stylesEdit) || {}
            if (tempStyles.hoverOpacity) {
                tempStyles.transition = 'all 0.3s ease'
                tempStyles2.transition = 'all 0.3s ease'
            }
            if (object.layout_type === 'n' && !object.is_mixed_media) {
                tempStyles.fontFamily = getStylesToRetrieve(reduxModalSite, undefined, reduxCacheSiteportfoliostyles, '', 'fontFamily')
                tempStyles.position = 'fixed'
                tempStyles.top = '0px'
                if (!(deviceType === 'is-web' && object.data_json?.navbar_is_side_menu)) {
                    tempStyles.width = '100%'
                }
                if (reduxModalSite.languageId === 19) {
                    tempStyles.direction = 'rtl'
                }
                tempStyles.zIndex = 999
                if (reduxFormSitenavigationMode) {
                    if (reduxModalSite.specialSize === 'form-fullscreen') {
                        tempStyles.left = '0px'
                        tempStyles.top = '45px'
                    } else {
                        tempStyles.left = '400px'
                        tempStyles.top = '75px'
                    }
                    if (!(deviceType === 'is-web' && object.data_json?.navbar_is_side_menu)) {
                        if (reduxModalSite.specialSize === 'form-fullscreen') {
                            tempStyles.width = '100vw'
                        } else {
                            tempStyles.width = 'calc(100vw - 430px)'
                        }
                        if (deviceType === 'is-tablet') {
                            tempStyles.width = '768px'
                        }
                        if (deviceType === 'is-mobile') {
                            tempStyles.width = '375px'
                        }
                    }
                }
            }
            if (object.layout_type === 'p') {
                tempStyles.display = 'none'
                tempStyles.position = 'fixed'
                tempStyles.top = '0px'
                if (reduxModalSite.navbarOpenId && (reduxModalSite.navbarOpenId === parentNavbarId) && (tempStyles.realDisplay !== 'none')) {
                    tempStyles.display = ''
                }
                if (reduxFormSitenavigationMode) {
                    tempStyles.left = '400px'
                    tempStyles.top = '75px'
                    tempStyles.width = 'calc(100vw - 430px)'
                    if (deviceType === 'is-tablet') {
                        tempStyles.width = '768px'
                    }
                    if (deviceType === 'is-mobile') {
                        tempStyles.width = '375px'
                    }
                }
            }
            if (deviceType !== 'is-web' && object.layout_type === 'pr') {
                tempStyles.display = 'none'
                tempStyles.left = '0px'
                tempStyles.position = 'fixed'
                tempStyles.top = '0px'
                tempStyles.width = '100%'
                if (reduxModalSite.navbarOpenId && reduxModalSite.navbarOpenId === parentNavbarId) {
                    tempStyles.display = ''
                }
                if (reduxFormSitenavigationMode) {
                    tempStyles.left = '400px'
                    tempStyles.top = '75px'
                    tempStyles.width = 'calc(100vw - 430px)'
                    if (deviceType === 'is-tablet') {
                        tempStyles.width = '768px'
                    }
                    if (deviceType === 'is-mobile') {
                        tempStyles.width = '375px'
                    }
                }
            }
            if (tempStyles.alternateFlexDirection === 'true') {
                const flexDict: any = {
                    'column': 'column-reverse',
                    'column-reverse': 'column',
                    'row': 'row-reverse',
                    'row-reverse': 'row',
                }
                const currentFlexDirection = tempStyles.flexDirection
                if (currentFlexDirection) {
                    tempStyles2.flexDirection = flexDict[currentFlexDirection]
                }
            }
            return {
                animation: `${tempStyles.animationName ? ' animation' : ''}`,
                hiddenHorizontalScroll: Boolean(tempStyles.overflowX),
                styles: tempStyles,
                styles2: tempStyles2,
                stylesActive: !object.action ? undefined : {
                    background: tempStyles.hoverBackground,
                    opacity: tempStyles.hoverOpacity,
                },
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock780Site',
                'applyStyles',
            ))
        }
    }

    function getChildren(object: any, id_to: number) {
        try {
            if (Array.isArray(object?.content)) {
                let canStop = false;
                for (let i = 0; i < object.content.length; i++) {
                    if (!canStop && object.content[i]?.id) {
                        if (object.content[i].id === id_to) {
                            // console.log('inListIndex', inListIndex);
                            // console.log('object.content[i].id', object.content[i].id);
                            // console.log('object.content[i].is_list', object.content[i].is_list);
                            // console.log('object.content[i].layout', object.content[i].layout);
                            // console.log('object.content[i].content?.text', object.content[i].content?.text);
                            // console.log('object.content[i].content?.text_html', object.content[i].content?.text_html);
                            // console.log('object.content[i].content', object.content[i].content);
                            if (!object.content[i].is_list && object.content[i].layout === 779) {
                                let textToCheck: any = ''
                                if (typeof inListIndex === 'undefined') {
                                    textToCheck = object.content[i].content
                                } else {
                                    textToCheck = object.content[i].content?.[inListIndex!]
                                }
                                // console.log('textToCheck', textToCheck);
                                // console.log('textToCheck?.text_html', textToCheck?.text_html);
                                // console.log('textToCheck?.text', textToCheck?.text);
                                // console.log('textToCheck?.text?.length', textToCheck?.text?.length);
                                // console.log('textToCheck?.text?.value', textToCheck?.text?.value);
                                let canHide = true
                                if (textToCheck?.text_html) canHide = false
                                if (Array.isArray(textToCheck?.text)) {
                                    if (textToCheck?.text.length > 0) canHide = false
                                } else if (textToCheck?.text?.hasOwnProperty('value')) {
                                    if (textToCheck?.text?.value) canHide = false
                                } else if (textToCheck?.text) {
                                    canHide = false
                                }
                                if (canHide) {
                                    setConnectedHidden(true)
                                }
                            } else if (!object.content[i].is_list && object.content[i].layout === 786 && object.content[i].content?.team?.length === 0) {
                                setConnectedHidden(true)
                            } else if (object.content[i].content[0]?.content_ids?.length === 0) {
                                setConnectedHidden(true)
                            }
                            canStop = true
                            return object.id
                        } else {
                            getChildren(object.content[i], id_to)
                        }
                    }
                }
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock780Site',
                'getChildren',
            ))
        }
    }

    function onLinkClick() {
        try {
            if (object.action === 'close_menu') {
                dispatch(reduxModalSetNavbarOpenIdSite(undefined))
            }
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock780Site',
                'onLinkClick',
            ))
        }
    }

    if (connectedHidden) return null
    if (object.auth_type === 'co' && !reduxFormSitenavigationMode && !reduxAuth?.authenticated) return null
    if (object.auth_type === 'con' && !reduxFormSitenavigationMode && reduxAuth?.authenticated) return null
    if (object.content_detail?.is_hidden && !reduxFormSitenavigationMode) return null
    if ([322333, 331027].includes(object.id!) && window.location.pathname.includes('/entity-detail/')) return null  // TODO dynamic
    if ([331724, 331719].includes(object.id!) && !window.location.pathname.includes('/entity-detail/')) return null  // TODO dynamic

    if (object.is_list) {
        let contentIds = content[0]?.content_ids || []
        if (!reduxFormSitenavigationMode) {
            contentIds = content[0]?.content_ids?.filter(obj => obj.is_hidden !== true) || []
        }
        return (
            <React.Fragment>
                {contentIds.map((val2: any, i: number) => {
                    if (stylesNew?.styles?.customAnimationConfig) {
                        return (
                            <Animated780Site
                                key={val2.id}
                                blockId={`${blockId}-${val2.id}`}
                                className={`template-block-780 lg-${reduxModalSite.languageId}${stylesNew?.animation ? ` ${stylesNew?.animation}` : ''}${stylesNew?.hiddenHorizontalScroll ? ' mo-hidden-horizontal-scrollbar' : ''} list${reduxFormSiteparentContent === object.id ? ' is-parent-hovered' : ''}${isEditHovered ? ' is-edit-hovered' : ''}${object.action ? ' transition' : ''}${reduxModalSite.isWindows} ${deviceType}`}
                                style={(i % 2 === 0) ? stylesNew?.styles : stylesNew?.styles2}
                            >
                                {content.filter(obj => obj.active === true).map((val) => {
                                    // @ts-ignore
                                    const Component = templateBlock[`TemplateBlock${val.layout}Site`]
                                    const contentBlockId = `content-new-child-${val.id}-layout-${val.layout}`
                                    return (
                                        <Component
                                            key={val.id}
                                            blockId={contentBlockId}
                                            collapseIsOpen={collapseIsOpen}
                                            content={groupingIdsSite.includes(val.layout!) ? val.content : val.content[i]}
                                            contentArray={contentIds}
                                            detailId={detailId}
                                            devId={devId}
                                            disableInfiniteScroll={true}
                                            formInfo={formInfo}
                                            formInputObj={formInputObj}
                                            formValue={formValue}
                                            hasMore={false}
                                            hubComponentType={hubComponentType}
                                            hubContentSlug={hubContentSlug}
                                            inAbout={inAbout}
                                            inFormModal={inFormModal}
                                            inListIndex={i}
                                            infiniteContentId={infiniteContentId}
                                            isEditHovered={reduxFormSitehoveredBlockId === val.id}
                                            isInComponent={isInComponent || ['f', 'n'].includes(object.layout_type!)}
                                            isInDetailPage={isInDetailPage}
                                            isInVideoBlock={isInVideoBlock || object.layout_type === 'v'}
                                            linkColor={linkColor}
                                            listIndex={i}
                                            mainParentId={mainParentId || object.id}
                                            object={val}
                                            onSearchNext={undefined}
                                            pageNumberNew={pageNumberNew}
                                            pageSlug={pageSlug}
                                            parentArray={parentArray ? [object.id, ...parentArray] : [object.id]}
                                            parentDropdownId={parentDropdownId}
                                            parentNavbarId={object.layout_type === 'n' ? object.id : parentNavbarId}
                                            parentStyles={object.styles}
                                            setFormValue={setFormValue}
                                            styles={val.styles}
                                            stylesEdit={reduxFormSiteeditAutoSave[val.id!]?.styles || reduxFormSiteeditAutoSaved[val.id!]?.styles}
                                        />
                                    )
                                })}
                            </Animated780Site>
                        )
                    }
                    return (
                        <div
                            key={val2.id}
                            id={`${blockId}-${val2.id}`}
                            className={`template-block-780 lg-${reduxModalSite.languageId}${stylesNew?.animation ? ` ${stylesNew?.animation}` : ''}${stylesNew?.hiddenHorizontalScroll ? ' mo-hidden-horizontal-scrollbar' : ''} list${reduxFormSiteparentContent === object.id ? ' is-parent-hovered' : ''}${isEditHovered ? ' is-edit-hovered' : ''}${object.action ? ' transition' : ''}${reduxModalSite.isWindows} ${deviceType}`}
                            style={(i % 2 === 0) ? stylesNew?.styles : stylesNew?.styles2}
                        >
                            {content.filter(obj => obj.active === true).map((val) => {
                                // @ts-ignore
                                const Component = templateBlock[`TemplateBlock${val.layout}Site`]
                                const contentBlockId = `content-new-child-${val.id}-layout-${val.layout}`
                                return (
                                    <Component
                                        key={val.id}
                                        blockId={contentBlockId}
                                        collapseIsOpen={collapseIsOpen}
                                        content={groupingIdsSite.includes(val.layout!) ? val.content : val.content[i]}
                                        contentArray={contentIds}
                                        detailId={detailId}
                                        devId={devId}
                                        disableInfiniteScroll={true}
                                        formInfo={formInfo}
                                        formInputObj={formInputObj}
                                        formValue={formValue}
                                        hasMore={false}
                                        hubComponentType={hubComponentType}
                                        hubContentSlug={hubContentSlug}
                                        inAbout={inAbout}
                                        inFormModal={inFormModal}
                                        inListIndex={i}
                                        infiniteContentId={infiniteContentId}
                                        isEditHovered={reduxFormSitehoveredBlockId === val.id}
                                        isInComponent={isInComponent || ['f', 'n'].includes(object.layout_type!)}
                                        isInDetailPage={isInDetailPage}
                                        isInVideoBlock={isInVideoBlock || object.layout_type === 'v'}
                                        linkColor={linkColor}
                                        listIndex={i}
                                        mainParentId={mainParentId || object.id}
                                        object={val}
                                        onSearchNext={undefined}
                                        pageNumberNew={pageNumberNew}
                                        pageSlug={pageSlug}
                                        parentArray={parentArray ? [object.id, ...parentArray] : [object.id]}
                                        parentDropdownId={parentDropdownId}
                                        parentNavbarId={object.layout_type === 'n' ? object.id : parentNavbarId}
                                        parentStyles={object.styles}
                                        setFormValue={setFormValue}
                                        styles={val.styles}
                                        stylesEdit={reduxFormSiteeditAutoSave[val.id!]?.styles || reduxFormSiteeditAutoSaved[val.id!]?.styles}
                                    />
                                )
                            })}
                        </div>
                    )
                })}
                {object.infinite_list_page_size && contentIds.length >= object.infinite_list_page_size && (
                    <InfiniteBlock
                        contentIdsAll={[...contentIdsAll || [], ...contentIds]}
                        detailId={detailId}
                        devId={devId}
                        hubComponentType={hubComponentType}
                        hubContentSlug={hubContentSlug}
                        inAbout={inAbout}
                        infiniteContentId={object.id!}
                        isButton={object.data_json?.infinite_list_button!}
                        isRandomOrdering={object.data_json?.is_random_ordering!}
                        linkColor={linkColor}
                        pageNumberNew={(pageNumberNew || 1) + 1}
                        pageSlug={pageSlug}
                    />
                )}
            </React.Fragment>
        )
    }

    const detailObject = object.content_detail || object.content_ids?.[inListIndex!] || contentArray?.[inListIndex!]
    if (detailObject?.is_hidden && !reduxFormSitenavigationMode) return null

    if (isInVideoBlock && reduxPlayerSiteplayingInlineId && reduxPlayerSiteplayingInlineId === detailObject?.id && !reduxPlayerSitekeepNextBlocks) return null

    if (stylesNew?.styles?.customAnimationConfig) {
        return (
            <Animated780Site
                blockId={blockId}
                className={`template-block-780 lg-${reduxModalSite.languageId}${stylesNew?.animation ? ` ${stylesNew?.animation}` : ''}${stylesNew?.hiddenHorizontalScroll ? ' mo-hidden-horizontal-scrollbar' : ''} detail${reduxFormSiteparentContent === object.id ? ' is-parent-hovered' : ''}${isEditHovered ? ' is-edit-hovered' : ''}${object.action ? ' transition' : ''}${reduxModalSite.isWindows} ${deviceType}`}
                style={stylesNew?.styles}
            >
                {content?.filter(obj => obj.active === true).map((val) => {
                    // @ts-ignore
                    const Component = templateBlock[`TemplateBlock${val.layout}Site`]
                    const contentBlockId = `content-new-child-${val.id}-layout-${val.layout}`
                    return (
                        <Component
                            key={val.id}
                            blockId={contentBlockId}
                            collapseIsOpen={collapseIsOpen}
                            content={(!groupingIdsSite.includes(val.layout!) && inListIndex !== undefined) ? val.content[inListIndex] : val.content}
                            contentArray={contentArray}
                            detailId={detailId}
                            devId={devId}
                            disableInfiniteScroll={true}
                            formInfo={formInfo}
                            formInputObj={formInputObj}
                            formValue={formValue}
                            hasMore={false}
                            hubComponentType={hubComponentType}
                            hubContentSlug={hubContentSlug}
                            inAbout={inAbout}
                            inFormModal={inFormModal}
                            inListIndex={groupingIdsSite.includes(val.layout!) ? inListIndex : undefined}
                            infiniteContentId={infiniteContentId}
                            isEditHovered={reduxFormSitehoveredBlockId === val.id}
                            isInComponent={isInComponent || ['f', 'n'].includes(object.layout_type!)}
                            isInDetailPage={isInDetailPage}
                            isInLayoutTypePr={isInLayoutTypePr || val.layout_type === 'pr'}
                            isInVideoBlock={isInVideoBlock || object.layout_type === 'v'}
                            linkColor={linkColor}
                            listIndex={listIndex}
                            mainParentId={mainParentId || object.id}
                            object={val}
                            onSearchNext={undefined}
                            pageNumberNew={pageNumberNew}
                            pageSlug={pageSlug}
                            parentArray={parentArray ? [object.id, ...parentArray] : [object.id]}
                            parentDropdownId={parentDropdownId}
                            parentNavbarId={object.layout_type === 'n' ? object.id : parentNavbarId}
                            parentObject={object}
                            parentStyles={object.styles}
                            setFormValue={setFormValue}
                            styles={val.styles}
                            stylesEdit={reduxFormSiteeditAutoSave[val.id!]?.styles || reduxFormSiteeditAutoSaved[val.id!]?.styles}
                        />
                    )
                })}
            </Animated780Site>
        )
    }

    return (
        <LinkHelperSite
            id={blockId}
            action={object.action}
            className={`template-block-780 lg-${reduxModalSite.languageId}${stylesNew?.animation ? ` ${stylesNew?.animation}` : ''}${stylesNew?.hiddenHorizontalScroll ? ' mo-hidden-horizontal-scrollbar' : ''} detail${reduxFormSiteparentContent === object.id ? ' is-parent-hovered' : ''}${isEditHovered ? ' is-edit-hovered' : ''}${object.action ? ' transition' : ''}${reduxModalSite.isWindows} ${deviceType}`}
            linkUrl={detailObject?.link_url}
            onClick={() => detailObject ? onClickIsModalSite(dispatch, reduxAuth, reduxModalSite, detailObject, object, (contentArray || [detailObject]), (isInVideoBlock || object.layout_type === 'v')) : onLinkClick()}
            style={stylesNew?.styles}
            styleActive={stylesNew?.stylesActive}
            to={object.absolute_content_site_url || detailObject?.absolute_site_url}
        >
            {content?.filter(obj => obj.active === true).map((val) => {
                // @ts-ignore
                const Component = templateBlock[`TemplateBlock${val.layout}Site`]
                const contentBlockId = `content-new-child-${val.id}-layout-${val.layout}`
                return (
                    <Component
                        key={val.id}
                        blockId={contentBlockId}
                        collapseIsOpen={collapseIsOpen}
                        content={(!groupingIdsSite.includes(val.layout!) && inListIndex !== undefined) ? val.content[inListIndex] : val.content}
                        contentArray={contentArray}
                        detailId={detailId}
                        devId={devId}
                        disableInfiniteScroll={true}
                        formInfo={formInfo}
                        formInputObj={formInputObj}
                        formValue={formValue}
                        hasMore={false}
                        hubComponentType={hubComponentType}
                        hubContentSlug={hubContentSlug}
                        inAbout={inAbout}
                        inFormModal={inFormModal}
                        inListIndex={groupingIdsSite.includes(val.layout!) ? inListIndex : undefined}
                        infiniteContentId={infiniteContentId}
                        isEditHovered={reduxFormSitehoveredBlockId === val.id}
                        isInComponent={isInComponent || ['f', 'n'].includes(object.layout_type!)}
                        isInDetailPage={isInDetailPage}
                        isInLayoutTypePr={isInLayoutTypePr || val.layout_type === 'pr'}
                        isInVideoBlock={isInVideoBlock || object.layout_type === 'v'}
                        linkColor={linkColor}
                        listIndex={listIndex}
                        mainParentId={mainParentId || object.id}
                        object={val}
                        onSearchNext={undefined}
                        pageNumberNew={pageNumberNew}
                        pageSlug={pageSlug}
                        parentArray={parentArray ? [object.id, ...parentArray] : [object.id]}
                        parentDropdownId={parentDropdownId}
                        parentNavbarId={object.layout_type === 'n' ? object.id : parentNavbarId}
                        parentObject={object}
                        parentStyles={object.styles}
                        setFormValue={setFormValue}
                        styles={val.styles}
                        stylesEdit={reduxFormSiteeditAutoSave[val.id!]?.styles || reduxFormSiteeditAutoSaved[val.id!]?.styles}
                    />
                )
            })}
            {stylesNew?.animation === ' animation' && (
                content?.filter(obj => obj.active === true).map((val) => {
                    // @ts-ignore
                    const Component = templateBlock[`TemplateBlock${val.layout}Site`]
                    const contentBlockId = `content-new-child-${val.id}-layout-${val.layout}`
                    return (
                        <Component
                            key={val.id}
                            blockId={contentBlockId}
                            collapseIsOpen={collapseIsOpen}
                            content={(!groupingIdsSite.includes(val.layout!) && inListIndex !== undefined) ? val.content[inListIndex] : val.content}
                            contentArray={contentArray}
                            detailId={detailId}
                            devId={devId}
                            disableInfiniteScroll={true}
                            formInfo={formInfo}
                            formInputObj={formInputObj}
                            formValue={formValue}
                            hasMore={false}
                            hubComponentType={hubComponentType}
                            hubContentSlug={hubContentSlug}
                            inAbout={inAbout}
                            inFormModal={inFormModal}
                            inListIndex={groupingIdsSite.includes(val.layout!) ? inListIndex : undefined}
                            infiniteContentId={infiniteContentId}
                            isEditHovered={reduxFormSitehoveredBlockId === val.id}
                            isInComponent={isInComponent || ['f', 'n'].includes(object.layout_type!)}
                            isInDetailPage={isInDetailPage}
                            isInVideoBlock={isInVideoBlock || object.layout_type === 'v'}
                            linkColor={linkColor}
                            listIndex={listIndex}
                            mainParentId={mainParentId || object.id}
                            object={val}
                            onSearchNext={undefined}
                            pageNumberNew={pageNumberNew}
                            pageSlug={pageSlug}
                            parentArray={parentArray ? [object.id, ...parentArray] : [object.id]}
                            parentDropdownId={parentDropdownId}
                            parentNavbarId={object.layout_type === 'n' ? object.id : parentNavbarId}
                            parentStyles={object.styles}
                            setFormValue={setFormValue}
                            styles={val.styles}
                            stylesEdit={reduxFormSiteeditAutoSave[val.id!]?.styles || reduxFormSiteeditAutoSaved[val.id!]?.styles}
                        />
                    )
                })
            )}
        </LinkHelperSite>
    )
})
