// react components
import React, {
    useEffect,
    useState,
} from 'react'
import Linkify from 'react-linkify'
import Input from 'react-phone-number-input/input'
import {
    useDispatch,
    useSelector,
} from 'react-redux'

// components
import {
    LinkHelperSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxFormModalFormShowSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// serializers
import {
    CustomCSSProperties,
    MainStyleSerializer,
    PortfolioPageContentListSiteSerializer,
    Template823SiteSerializer,
} from 'serializers/site'

// services
import {
    getDateWithTypeSite,
    getStylesNew,
} from 'services'

// styles
import 'react-phone-number-input/style.css'

// props
type TemplateBlock823SiteProps = {
    blockId: string
    content: Template823SiteSerializer | undefined
    isEditHovered: boolean
    object: PortfolioPageContentListSiteSerializer
    styles: MainStyleSerializer
    stylesEdit: MainStyleSerializer | undefined
}

// main
export const TemplateBlock823Site: React.FC<TemplateBlock823SiteProps> = React.memo(({
    blockId,
    content,
    isEditHovered,
    object,
    styles,
    stylesEdit,
}) => {

    const dispatch = useDispatch()
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const deviceType = reduxModalSite.deviceType

    const [stylesNew, setStylesNew] = useState<CustomCSSProperties | undefined>(applyStyles())

    useEffect(() => {
        setStylesNew(applyStyles())
    }, [
        object.id,
        reduxModalSite,
        styles,
        stylesEdit,
    ])

    function applyStyles() {
        try {
            if (process.env.NODE_ENV === 'development') console.log('TemplateBlock823Site', content)
            return getStylesNew(reduxModalSite, styles, stylesEdit)
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock823Site',
                'applyStyles',
            ))
        }
    }

    return (
        <div
            id={blockId}
            className={`template-block-823${isEditHovered ? ' is-edit-hovered' : ''} ${deviceType}`}
        >
            <table className={`t-823-table ${deviceType}`}>
                <thead>
                    <tr className={`t-823-thead-tr ${deviceType}`}>
                        <th className={`t-823-thead-th ${deviceType}`}></th>
                        {content?.columns?.map(column => {
                            return (
                                <th
                                    key={column.key}
                                    className={`t-823-thead-th ${deviceType}`}
                                >
                                    <div className={`t-823-thead-th-div-wrap ${deviceType}`}>
                                        <div className={`t-823-thead-th-div ${deviceType}`}>{column.title}</div>
                                        {/* <div className={`th-filter-div ${deviceType}`}>
                                        <TableHeaderAction
                                            column={column}
                                            edit={false}
                                            fieldType={fieldOptions.fieldType}
                                            filterValues={filterValues}
                                            inModal={inModal}
                                            setFilter={setFilter}
                                            setShowActionPopover={setShowActionPopover}
                                            showActionPopover={showActionPopover}
                                        />
                                    </div> */}
                                    </div>
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {content?.data?.map(row => {
                        return (
                            <tr
                                key={row.id}
                                className={`t-823-tbody-tr ${deviceType}`}
                            >
                                <td>
                                    <div className={`t-823-actions ${deviceType}`}>
                                        {row.absolute_site_url && (
                                            <LinkHelperSite
                                                action='generic_link'
                                                onClick={() => undefined}
                                                to={row.absolute_site_url}
                                            >
                                                <i className={`main-icon mo-new-icon-eye-solid ${deviceType}`} />
                                            </LinkHelperSite>
                                        )}
                                        {row.can_edit && (
                                            <LinkHelperSite
                                                action=''
                                                onClick={() => dispatch(reduxFormModalFormShowSite(true, content.content_type_label_model, row.id))}
                                                to={undefined}
                                            >
                                                <i className={`main-icon mo-new-icon-pencil-alt-solid ${deviceType}`} />
                                            </LinkHelperSite>
                                        )}
                                    </div>
                                </td>
                                {content?.columns?.map(column => {
                                    // @ts-ignore
                                    const value = row[column.key]
                                    switch (column.field_type) {
                                        case 'BooleanField':
                                            return (
                                                <td key={column.key}>
                                                    <i className={`t-823-value boolean ${value ? 'mo-new-icon-check-circle-solid' : 'mo-new-icon-times-solid'} ${deviceType}`} />
                                                </td>
                                            )
                                        case 'DateField':
                                            return (
                                                <td key={column.key}>
                                                    <p className={`t-823-value ${deviceType}`}>{getDateWithTypeSite(reduxModalSite, value, '', 'DD-MM-YYYY')}</p>
                                                </td>
                                            )
                                        case 'FileField':
                                            return (
                                                <td key={column.key}>
                                                    {value
                                                        ? (
                                                            <a
                                                                className={`t-823-value ${deviceType}`}
                                                                href={value}
                                                                rel='noreferrer'
                                                                target='_blank'
                                                            >
                                                                <i className={`main-icon mo-new-icon-paperclip-solid ${deviceType}`} />
                                                            </a>
                                                        )
                                                        : null
                                                    }

                                                </td>
                                            )
                                        case 'ForeignKey':
                                            if (column.key?.split('__')[0] === 'custom_foreign_key_1') {
                                                if (row[column.key?.split('__')[0]]?.id === 48) {
                                                    return (
                                                        <td key={column.key}>
                                                            <i className={`t-823-value boolean mo-new-icon-check-circle-solid ${deviceType}`} />
                                                        </td>
                                                    )
                                                }
                                                if (row[column.key?.split('__')[0]]?.id === 49) {
                                                    return (
                                                        <td key={column.key}>
                                                            <i className={`t-823-value boolean mo-new-icon-times-solid ${deviceType}`} />
                                                        </td>
                                                    )
                                                }
                                            }
                                            if (column.key?.split('__')[0] === 'custom_foreign_key_2') {
                                                if (row[column.key?.split('__')[0]]?.id === 46) {
                                                    return (
                                                        <td key={column.key}>
                                                            <i className={`t-823-value boolean mo-new-icon-check-circle-solid ${deviceType}`} />
                                                        </td>
                                                    )
                                                }
                                                if (row[column.key?.split('__')[0]]?.id === 47) {
                                                    return (
                                                        <td key={column.key}>
                                                            <i className={`t-823-value boolean mo-new-icon-times-solid ${deviceType}`} />
                                                        </td>
                                                    )
                                                }
                                            }
                                            return (
                                                <td key={column.key}>
                                                    {/* @ts-ignore */}
                                                    <p className={`t-823-value ${deviceType}`}>{row[column.key?.split('__')[0]]?.[column.key?.split('__')[1]]}</p>
                                                </td>
                                            )
                                        case 'ManyToManyField':
                                            return (
                                                <td key={column.key}>
                                                    {/* @ts-ignore */}
                                                    <p className={`t-823-value ${deviceType}`}>{row[column.key?.split('__')[0]]?.map((item: any) => item.name).join(', ')}</p>
                                                </td>
                                            )
                                        case 'PhoneNumberField':
                                            return (
                                                <td key={column.key}>
                                                    <p className={`t-823-value ${deviceType}`}>
                                                        <Input
                                                            className={`phonenumber ${deviceType}`}
                                                            disabled
                                                            onChange={() => undefined}
                                                            value={value}
                                                        />
                                                    </p>
                                                </td>
                                            )
                                        default:
                                            return (
                                                <td key={column.key}>
                                                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => <a key={key} href={decoratedHref} target='blank'>{decoratedText}</a>}>
                                                        <p className={`t-823-value ${deviceType}`}>{value}</p>
                                                    </Linkify>
                                                </td>
                                            )
                                    }
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
})
