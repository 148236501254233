// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    IonContent,
    IonPage,
} from '@ionic/react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    Redirect,
    useLocation,
} from 'react-router-dom'

// components
import {
    IconBlockSite,
    LoaderSite,
} from 'components'

// data
import {
    defaultReduxState,
    reduxAuthLoginSite,
    reduxModalErrorEventHandlerSite,
} from 'data'

// pages
import {
    NotFoundPortfolioSite,
} from 'pages'

// serializers
import {
    errorStatusTypeSite,
} from 'serializers/site'

// services
import {
    getPortfolioDataSite,
} from 'services'

// props
type StaffLoginPageSiteProps = {
    devId: string
}

// main
export const StaffLoginPageSite: React.FC<StaffLoginPageSiteProps> = React.memo(({
    devId,
}) => {

    const dispatch = useDispatch()
    const location = useLocation()
    const reduxAuth = useSelector((state: defaultReduxState) => state.reduxAuth)
    const reduxCacheSite = useSelector((state: defaultReduxState) => state.reduxCacheSite)
    const reduxCacheSiteportfolioid = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.id)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)
    const reduxText = useSelector((state: defaultReduxState) => state.reduxText.data)

    const deviceType = reduxModalSite.deviceType

    type errorsType = {
        detail?: string
        non_field_errors?: string
        password?: string
        username?: string
    }
    type fieldsType = {
        password: string
        username: string
    }
    const errorsInitial = {}
    const fieldsInitial = {
        password: '',
        username: '',
    }

    const [errorStatus, setErrorStatus] = useState<errorStatusTypeSite>({})

    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [errors, setErrors] = useState<errorsType>(errorsInitial)
    const [fields, setFields] = useState<fieldsType>(fieldsInitial)
    const [isLoading, setIsLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    useEffect(() => {
        getPortfolioDataSite({
            axiosCancelToken: undefined,
            backgroundLoad: true,
            component: 'StaffLoginPageSite',
            detailId: undefined,
            devId,
            dispatch,
            errorStatus,
            location,
            pageSlug: undefined,
            reduxAuth,
            reduxCacheSite,
            reduxModalSite,
            scrollToTop: undefined,
            setErrorStatus,
            setIsLoading,
            setPageContentItems: () => undefined,
            setPageObject: () => undefined,
        })
    }, [])

    function handleInputChange(event: any) {
        try {
            const name = event.target.name
            setButtonDisabled(false)
            setFields({ ...fields, [name]: event.target.value })
            setErrors({ ...errors, [name]: '' })
        } catch (error) {
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock806LoginSite',
                'handleInputChange',
            ))
        }
    }

    function onSubmit() {
        try {
            setButtonDisabled(true)
            setIsLoading(true)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(true) onSubmit')
            // @ts-ignore
            dispatch(reduxAuthLoginSite(
                fields.username,
                fields.password,
                reduxAuth,
                reduxModalSite,
                dispatch,
                setIsLoading,
                setFields,
                fieldsInitial,
                reduxCacheSiteportfolioid!,
                setErrors,
            ))
        } catch (error) {
            setIsLoading(false)
            if (process.env.NODE_ENV === 'development') console.log('setIsLoading(false) onSubmit')
            dispatch(reduxModalErrorEventHandlerSite(
                error,
                'TemplateBlock806LoginSite',
                'onSubmit',
            ))
        }
    }

    if (errorStatus.portfolio || errorStatus.page || errorStatus.pageCcontent) {
        return (
            <NotFoundPortfolioSite statusCode={errorStatus.portfolio || errorStatus.page || errorStatus.pageCcontent} />
        )
    }
    if (reduxAuth?.authenticated) {
        return <Redirect to={reduxModalSite.rootUrl || '/'} />
    }
    return (
        <IonPage className='login-page-site navbar-pt'>
            <IonContent className={`mo-hide-ion-content-scrollbar${reduxModalSite.isWindows} ${deviceType}`}>
                <div className='lps-content'>
                    <div className='lps-left auth-global-block-web'>
                        <h1 className='lps-title'>{reduxText[9839]}</h1>
                        <div className={`lps-input-wrap ${deviceType}`}>
                            <p className={`lps-label ${deviceType}`}>
                                {reduxText[8421]}
                            </p>
                            <input
                                className={`lps-input ${deviceType}`}
                                name='username'
                                onChange={handleInputChange}
                                tabIndex={1}
                                type='text'
                            />
                            {errors?.username && (
                                <span className={`lps-error ${deviceType}`}>{errors?.username}</span>
                            )}
                        </div>
                        <div className={`lps-input-wrap ${deviceType}`}>
                            <div className={`lps-label-wrap ${deviceType}`}>
                                <p className={`lps-label ${deviceType}`}>
                                    {reduxText[8422]}
                                </p>
                                <IconBlockSite
                                    className={`lps-icon ${deviceType}`}
                                    iconClass={showPassword ? 'mo-new-icon-eye-slash-solid' : 'mo-new-icon-eye-solid'}
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                            </div>
                            <input
                                className={`lps-input ${deviceType}`}
                                name='password'
                                onChange={handleInputChange}
                                tabIndex={2}
                                type={showPassword ? 'text' : 'password'}
                            />
                            {errors?.password && (
                                <span className={`lps-error ${deviceType}`}>{errors?.password}</span>
                            )}
                        </div>
                        <div className={`lps-button-wrap ${deviceType}`}>
                            <button
                                className={`lps-button ${deviceType}`}
                                disabled={buttonDisabled || isLoading}
                                onClick={onSubmit}
                                type='button'
                            >
                                {reduxText[8424]}
                            </button>
                            {(errors?.non_field_errors || errors?.detail) && (
                                <span className={`lps-error ${deviceType}`}>{`${errors.non_field_errors || errors.detail}`}</span>
                            )}
                        </div>
                    </div>
                    <div
                        className='lps-right'
                        style={{
                            backgroundImage: `url('${reduxText[9840]}')`,
                        }}
                    />
                </div>
                {isLoading && (
                    <LoaderSite isOpen />
                )}
            </IonContent>
        </IonPage>
    )
})
