// react components
import React, {
    useEffect,
    useState,
} from 'react'
import {
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    Redirect,
    useHistory,
    useLocation,
    useParams,
} from 'react-router-dom'

// data
import {
    defaultReduxState,
    languageObjectSite,
    reduxModalSliderDetailShowHideSite,
} from 'data'

// pages
import {
    ComingSoonSite,
    DetailPageSite,
    HubPageSite,
    NotFoundWrapperSite,
    PrivateSlugPageSite,
} from 'pages'

// services
import {
    getHostOriginSite,
    parseQuerySite,
    stringifyQuery,
} from 'services'

// props
type MatchParams = {
    devId: string
    param1: string
    param2: string
    param3: string
}

// main
export const Param3WrapperSite: React.FC = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const params = useParams<MatchParams>()
    const reduxCacheSiteportfoliocoming_soon = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.coming_soon)
    const reduxCacheSiteportfoliourl_redirect = useSelector((state: defaultReduxState) => state.reduxCacheSite.portfolio?.url_redirect)
    const reduxModalSite = useSelector((state: defaultReduxState) => state.reduxModalSite)

    const [locationKeys, setLocationKeys] = useState<any[]>([])

    useEffect(() => {
        return history.listen(location => {
            if (history.action === 'PUSH') {
                setLocationKeys([location.key])
            }
            if (history.action === 'POP') {

                let listUrl = `${location.pathname}`  // Keep quotes
                const parsed = parseQuerySite(location.search)
                delete parsed.modal_page
                delete parsed.modal_detail_id
                if (parsed && Object.keys(parsed).length > 0 && parsed.constructor === Object) {
                    listUrl += `?${stringifyQuery(parsed)}`
                }

                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys)
                    if (parseQuerySite(location.search)?.modal_page) {
                        dispatch(reduxModalSliderDetailShowHideSite(
                            true,
                            listUrl,
                            parseQuerySite(location.search)?.modal_detail_id,
                        ))
                    }
                } else {
                    setLocationKeys((keys) => [location.key, ...keys])
                    if (parseQuerySite(location.search)?.modal_page) {
                        dispatch(reduxModalSliderDetailShowHideSite(
                            true,
                            listUrl,
                            parseQuerySite(location.search)?.modal_detail_id,
                        ))
                    }
                }
            }
        })
    }, [
        locationKeys,
    ])

    if (reduxCacheSiteportfoliocoming_soon) {
        return (
            <ComingSoonSite />
        )
    }

    const searchParams = parseQuerySite(location.search)
    const modal_page = searchParams.modal_page
    const modal_detail_id = searchParams.modal_detail_id

    if (!reduxModalSite.isOpen && !reduxModalSite.noRedirect && modal_page) {
        if (languageObjectSite[params.param1]) {
            return <Redirect to={`${reduxModalSite.rootUrl}${params.param1}/${modal_page}${modal_detail_id ? `/${modal_detail_id}` : ''}`} />
        }
        return <Redirect to={`${reduxModalSite.rootUrl}${modal_page}${modal_detail_id ? `/${modal_detail_id}` : ''}`} />
    }

    if (reduxCacheSiteportfoliourl_redirect?.length! > 0) {
        const hostOriginSite = getHostOriginSite()
        let redirectUrl = ''
        let remainingUrl = ''
        if (languageObjectSite[params.param1]) {
            const urlLang = params.param1
            remainingUrl = `${location.pathname.split(`/${params.param1}/`)[1]}${location.search}`
            const redirectObj = reduxCacheSiteportfoliourl_redirect!.find((obj) => obj.old_url === remainingUrl || `${obj.old_url}/` === remainingUrl)
            if (redirectObj) {
                if (hostOriginSite === 'prod') {
                    redirectUrl = `/${urlLang}/${redirectObj.new_url}`
                } else {
                    redirectUrl = `/${params.devId}/${urlLang}/${redirectObj.new_url}`
                }
            }
        } else {
            if (hostOriginSite === 'prod') {
                remainingUrl = `${location.pathname.substring(1)}${location.search}`
            } else {
                remainingUrl = `${location.pathname.split(params.devId)[1]?.substring(1)}${location.search}`
            }
            const redirectObj = reduxCacheSiteportfoliourl_redirect!.find((obj) => obj.old_url === remainingUrl || `${obj.old_url}/` === remainingUrl)
            if (redirectObj) {
                if (redirectObj) {
                    if (hostOriginSite === 'prod') {
                        redirectUrl = `/${redirectObj.new_url}`
                    } else {
                        redirectUrl = `/${params.devId}/${redirectObj.new_url}`
                    }
                }
            }
        }

        if (redirectUrl) {
            return <Redirect to={redirectUrl} />
        }
    }

    if (languageObjectSite[params.param1] && Number(params.param3.split('-')[0])) {
        return (
            <DetailPageSite
                detailId={params.param3}
                devId={params.devId}
                pageSlug={params.param2}
            />
        )
    }

    if (params.param1 === 'extra' && params.param2 === 'hub') {
        return (
            <HubPageSite
                devId={params.devId}
                tabSlug={params.param3}
            />
        )
    }

    if (params.param1 === 'extra' && params.param2 === 'private') {
        return (
            <PrivateSlugPageSite
                devId={params.devId}
                pageSlug={params.param3}
            />
        )
    }

    return (
        <NotFoundWrapperSite devId={params.devId} />
    )
}
